import { useNavigate } from "react-router-dom";
import "./../assets/css/rating.css";
import appAPI from "../api/service";
import { useEffect, useState } from "react";
import Loader from "../assets/js/loader";
import toast from "react-hot-toast";

export const Rating = () => {
  const nav = useNavigate();
  const [rating, setRating] = useState(null);

  useEffect(() => {
    async function fetch() {
      try {
        const res = await appAPI.getRating();
        console.log(res);
        if (res.status === "ok") {
          setRating(res.data);
        } else {
          nav("/");
        }
      } catch (e) {
        console.log(e)
      }
      
    }

    fetch();
  }, []);

  if (rating === null) return <Loader />;
  return (
    <div class="rating">
      <div class="rating_title">
        <img src="/Mask.svg" alt="" onClick={() => nav("/")} />
        <h1>Rating</h1>
      </div>
      <div className="squades"onClick={() => nav("/squads")}>
        <div>Squads</div>
      </div>
      {rating.length > 0 ? (
        <div class="rating_cards">
          {rating.map((rat) => {
            return (
              <div class="rating_card">
                {rat.photoBase64Url ? (
                  <img src={rat.photoBase64Url} class="rating_card_img" style={{background:"none"}} />
                ) : (
                  <div class="rating_card_img"></div>
                )}
                <div class="rating_card_about">
                  <h1>{rat.name}</h1>
                  <h2>{rat.balance} BIX</h2>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="not_found_text">Users rating is empty</div>
      )}
    </div>
  );
};
