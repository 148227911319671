export const baseURL = "https://bixtap.click";
// const auth = window.btoa(window.Telegram.WebApp.initData);
const auth = window.btoa(window.Telegram.WebApp.initData); 
/*const auth = window.btoa( 
  "query_id=AAFEDzRNAAAAAEQPNE18tejv&user=%7B%22id%22%3A6540281438%2C%22first_name%22%3A%22Daniil%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22d9A2nY3A%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1709476035&hash=ffccaf0d854ec756eee84a03bf4661a534e8ab4ead8f553651a0e83bb1a66194" 
);*/
//"query_id=AAFEDzRNAAAAAEQPNE18tejv&user=%7B%22id%22%3A6540281438%2C%22first_name%22%3A%22Daniil%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22d9A2nY3A%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1709476035&hash=ffccaf0d854ec756eee84a03bf4661a534e8ab4ead8f553651a0e83bb1a66194"
const appAPI = {
  async getUser(ref, squad) {
    console.log(auth);
    try {
      const params = {
        headers: {
          Authorization: auth,
          "ngrok-skip-browser-warning": 1,
        },
      };
      const res = await fetch(
        `${baseURL}/api/me${ref ? `?invitedBy=${ref}` : ""}${
          squad ? (ref ? `&squadId=${squad}` : `?squadId=${squad}`) : ""
        }`,
        params
      ).then((res) => res.json());
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async getRating() {
    try {
      const params = {
        headers: {
          Authorization: auth,
          "ngrok-skip-browser-warning": 1,
        },
      };
      const res = await fetch(`${baseURL}/api/rating`, params).then((res) =>
        res.json()
      );
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async getSquads() {
    try {
      const params = {
        headers: {
          Authorization: auth,
          "ngrok-skip-browser-warning": 1,
        },
      };
      const res = await fetch(`${baseURL}/api/squads`, params).then((res) =>
        res.json()
      );
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async click(clicks, key) {
    try {
      const params = {
        method: "POST",
        body: JSON.stringify({
          clicks: clicks,
          q: key,
        }),
        headers: {
          Authorization: auth,
          "ngrok-skip-browser-warning": 1,
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${baseURL}/api/click`, params).then((res) =>
        res.json()
      );
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async buyBoost(type) {
    try {
      const params = {
        method: "POST",
        body: JSON.stringify({
          type: type,
        }),
        headers: {
          Authorization: auth,
          "ngrok-skip-browser-warning": 1,
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${baseURL}/api/boost`, params).then((res) =>
        res.json()
      );
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async checkTask(task) {
    try {
      const params = {
        method: "POST",
        body: JSON.stringify({
          taskId: task.id,
        }),
        headers: {
          Authorization: auth,
          "ngrok-skip-browser-warning": 1,
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${baseURL}/api/checkTask`, params).then((res) =>
        res.json()
      );
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async exchange(amount, address) {
    try {
      const params = {
        method: "POST",
        body: JSON.stringify({
          amount: amount,
          address: address,
        }),
        headers: {
          Authorization: auth,
          "ngrok-skip-browser-warning": 1,
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${baseURL}/api/withdraw`, params).then((res) =>
        res.json()
      );
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async FrarmingStateINACTIVE() {},
};

export default appAPI;
