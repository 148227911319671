import { useEffect, useState } from "react";
import "./../assets/css/frens.css";
import Loader from "../assets/js/loader";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const Frens = ({ userInfo }) => {
  const nav = useNavigate();
  const [refferals, setRefferals] = useState(null);

  useEffect(() => {
    if (!userInfo) return;
    setRefferals(userInfo.refferals);
  }, []);

  const copyHandler = async (text) => {
    await navigator.clipboard.writeText(text);
    return toast.success("Сopied!");
  };

  if (refferals === null) return <Loader />;
  return (
    <div class="frens">
      <div class="frens_title">
        <img src="/Mask.svg" alt="" onClick={() => nav("/")} />
        <h1>Frens</h1>
        <div></div>
      </div>
      <div class="frens_link">
        <div class="frens_link_left">
          <h1>Your link</h1>
        </div>
        <div class="frens_link_right">
          <p
            onClick={() =>
              copyHandler(
                `https://t.me/bitbixtapclickbot?start=user_${userInfo.uid}`
              )
            }
          >
            https://t.me/bitbixtapclickbot?start=user_{userInfo.uid}
          </p>
          <span
            onClick={() =>
              copyHandler(
                `https://t.me/bitbixtapclickbot?start=user_${userInfo.uid}`
              )
            }
          >
            Click to copy
          </span>
        </div>
      </div>
      <div class="earn_balance">
        <div class="earn_balance_top">
          <p>Frens List</p>
        </div>
      </div>
      {refferals.length > 0 ? (
        <div class="frens_cards">
          {refferals.map((ref) => {
            return (
              <div class="frens_card">
                {ref.photoBase64Url ? (
                  <img
                    src={ref.photoBase64Url}
                    style={{ background: "none" }}
                    class="frens_card_img"
                  />
                ) : (
                  <div class="frens_card_img"></div>
                )}
                <div class="frens_card_about">
                  <h1>{ref.name}</h1>
                  <h2>{ref.awarded === true ? "Reward received" : "Reward in progress"}</h2>
                </div>
                <img src="/Mask2.svg" className="arrow" alt="" />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="not_found_text">Frens were not found</div>
      )}
    </div>
  );
};
