import { useNavigate } from "react-router-dom";
import "./../assets/css/boosts.css";
import { useEffect, useState } from "react";
import appAPI from "../api/service";
import toast from "react-hot-toast";

export const Boosts = ({ amount, userInfo, setUserInfo, setAmount }) => {
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);

  const [tap, setTaps] = useState(null);

  const [energy, setEnergies] = useState(null);

  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    setTaps(
      [
        {
          level: 1,
          price: 1000,
          byClick: 2,
        },
        {
          level: 2,
          price: 2000,
          byClick: 4,
        },
        {
          level: 3,
          price: 3000,
          byClick: 7,
        },
        {
          level: 4,
          price: 4000,
          byClick: 11,
        },
        {
          level: 5,
          price: 5000,
          byClick: 16,
        },
        {
          level: 6,
          price: 6000,
          byClick: 22,
        },
        {
          level: 7,
          price: 7000,
          byClick: 29,
        },
        {
          level: 8,
          price: 8000,
          byClick: 37,
        },
        {
          level: 9,
          price: 9000,
          byClick: 46,
        },
        {
          level: 10,
          price: 10000,
          byClick: 56,
        },
      ].find(
        (obj) =>
          obj.level ===
          (userInfo.clickLevel === 10 ? 10 : userInfo.clickLevel + 1)
      )
    );
    setEnergies(
      [
        {
          level: 1,
          price: 1000,
          maxEnergy: 1100,
        },
        {
          level: 2,
          price: 2000,
          maxEnergy: 1300,
        },
        {
          level: 3,
          price: 3000,
          maxEnergy: 1600,
        },
        {
          level: 4,
          price: 4000,
          maxEnergy: 2000,
        },
        {
          level: 5,
          price: 5000,
          maxEnergy: 2500,
        },
        {
          level: 6,
          price: 6000,
          maxEnergy: 3100,
        },
        {
          level: 7,
          price: 7000,
          maxEnergy: 3800,
        },
        {
          level: 8,
          price: 8000,
          maxEnergy: 4600,
        },
        {
          level: 9,
          price: 9000,
          maxEnergy: 5500,
        },
        {
          level: 10,
          price: 10000,
          maxEnergy: 6500,
        },
      ].find(
        (obj) =>
          obj.level ===
          (userInfo.energyLevel === 10 ? 10 : userInfo.energyLevel + 1)
      )
    );

    setRefresh(
      [
        {
          level: 1,
          price: 100,
          energySpeedBySecond: 2,
        },
        {
          level: 2,
          price: 200,
          energySpeedBySecond: 3,
        },
        {
          level: 3,
          price: 300,
          energySpeedBySecond: 4,
        },
        {
          level: 4,
          price: 400,
          energySpeedBySecond: 5,
        },
        {
          level: 5,
          price: 500,
          energySpeedBySecond: 6,
        },
        {
          level: 6,
          price: 600,
          energySpeedBySecond: 7,
        },
        {
          level: 7,
          price: 700,
          energySpeedBySecond: 8,
        },
        {
          level: 8,
          price: 800,
          energySpeedBySecond: 9,
        },
        {
          level: 9,
          price: 900,
          energySpeedBySecond: 10,
        },
        {
          level: 10,
          price: 1000,
          energySpeedBySecond: 11,
        },
      ].find(
        (obj) =>
          obj.level ===
          (userInfo.energySpeedLevel === 10
            ? 10
            : userInfo.energySpeedLevel + 1)
      )
    );
  }, [userInfo]);

  const handleBuy = async (type, obj) => {
    if (loading) return;
    if (obj.level === 10) return toast.error("Maximum level");
    setLoading(true);
    try {
      const res = await appAPI.buyBoost(type);
      if (res.status === "ok") {
        if (type === "click") {
          setUserInfo({
            ...userInfo,
            clickLevel: tap.level,
            byClick: obj.byClick,
          });
        } else if (type === "energy") {
          setUserInfo({
            ...userInfo,
            energyLevel: energy.level,
            maxEnergy: obj.maxEnergy,
          });
        } else if (type === "energySpeed") {
          setUserInfo({
            ...userInfo,
            energySpeedLevel: refresh.level,
            energySpeedBySecond: obj.energySpeedBySecond,
          });
        }
        setAmount(amount - obj.price);
        return toast.success("Successfully purchased");
      } else if (res.status === "error") {
        return toast.error(res.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div class="boosts">
      <div class="boosts_title">
        <img src="/Mask.svg" alt="" onClick={() => nav("/")} />
        <h1>Boosts</h1>
      </div>
      <div class="boosts_balance">
        <div class="boosts_balance_top">
          <p>Your balance:</p>
        </div>
        <div class="boosts_balance_down">
          <img src="/Coin Yellow 1 1.svg" alt="" />
          <h1>
            {amount} <span>BIX</span>
          </h1>
        </div>
      </div>
      <div class="boosts_cards">
        {tap && (
          <div
            className={`boosts_card ${tap.level === 10 ? "max_level" : ""}`}
            onClick={() => {
              tap.level < 10 && handleBuy("click", tap);
            }}
          >
            <div class="boosts_card_img">
              <img src="/up.svg" />
            </div>
            <div class="boosts_card_about">
              <h1>Multitap</h1>
              <div class="boosts_card_about2">
                {tap.level === 10 ? (
                  <h3>Maximum level</h3>
                ) : (
                  <>
                    <img src="/Coin Yellow 1 2.svg" alt="" />
                    <h2>{tap.price} BIX </h2>
                    <span>· {tap.level} level</span>
                  </>
                )}
              </div>
            </div>
            <img class="bxs-chevron-right" src="/Mask2.svg" alt="" />
          </div>
        )}

        {energy && (
          <div
            className={`boosts_card ${energy.level === 10 ? "max_level" : ""}`}
            onClick={() => {
              energy.level < 10 && handleBuy("energy", energy);
            }}
          >
            <div class="boosts_card_img">
              <img src="/flash.svg" />
            </div>
            <div class="boosts_card_about">
              <h1>Energy Level</h1>
              <div class="boosts_card_about2">
                {energy.level === 10 ? (
                  <h3>Maximum level</h3>
                ) : (
                  <>
                    <img src="/Coin Yellow 1 2.svg" alt="" />
                    <h2>{energy.price} BIX </h2>
                    <span>· {energy.level} level</span>
                  </>
                )}
              </div>
            </div>
            <img class="bxs-chevron-right" src="/Mask2.svg" alt="" />
          </div>
        )}

        {refresh && (
          <div
            className={`boosts_card ${refresh.level === 10 ? "max_level" : ""}`}
            onClick={() => {
              refresh.level < 10 && handleBuy("energySpeed", refresh);
            }}
          >
            <div class="boosts_card_img">
              <img src="/flash.svg" />
            </div>
            <div class="boosts_card_about">
              <h1>Energy Refresh Level</h1>
              <div class="boosts_card_about2">
                {refresh.level === 10 ? (
                  <h3>Maximum level</h3>
                ) : (
                  <>
                    <img src="/Coin Yellow 1 2.svg" alt="" />
                    <h2>{refresh.price} BIX </h2>
                    <span>· {refresh.level} level</span>
                  </>
                )}
              </div>
            </div>
            <img class="bxs-chevron-right" src="/Mask2.svg" alt="" />
          </div>
        )}
      </div>
    </div>
  );
};
