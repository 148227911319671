import { useNavigate } from "react-router-dom";
import "./../assets/css/withdraw.css";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import appAPI from "../api/service";

export const Withdraw = ({ balance, setBalance }) => {
  const nav = useNavigate();
  const [amount, setAmount] = useState(0);
  const [result, setResult] = useState(0);
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (amount === 0 || !amount) return setResult(0);
    setResult(parseInt(amount) / 5000000);
  }, [amount]);

  const handleExchange = async () => {
    if (!amount || amount === 0)
      return toast.error("Enter the quantity to excange");
    if (balance < amount)
      return toast.error("Your balance is less than the entered amount");
    if (!address || address === "") return toast.error("Enter wallet address");
    try {
      const res = await appAPI.exchange(amount, address);
      console.log(res);
      if (res.status === "ok") {
        toast.success("Successfully exchanged!");
        setBalance(parseInt(res.data.balance));
        return nav("/");
      } else {
        return res.message && toast.error(res.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div class="withdraw">
      <div class="withdraw_title">
        <img src="/Mask.svg" alt="" onClick={() => nav("/")} />
        <h1>Withdraw</h1>
      </div>
      <div class="earn_balance">
        <div class="earn_balance_top">
          <p>YOU SEND</p>
        </div>
      </div>
      <div class="withdraw_card">
        <div class="withdraw_card_block">
          <img src="/Coin Yellow 1 1.svg" alt="" />
          <h1>BIX</h1>
        </div>
        <input
          class="withdraw_card_num"
          value={amount}
          onChange={(e) => {
            if (isNaN(e.target.value.trim()))
              return toast.error("Please enter the number");
            setAmount(e.target.value);
          }}
          placeholder="0"
        />
      </div>
      <div class="earn_balance">
        <div class="earn_balance_top">
          <p>YOU RECEIVE</p>
        </div>
      </div>
      <div class="withdraw_card">
        <div class="withdraw_card_block">
          <img src="/Coin Yellow 1 1.svg" alt="" />
          <h1>BBX</h1>
        </div>
        <div class="withdraw_card_num">
          <span>{result}</span>
        </div>
      </div>
      <div class="earn_balance">
        <div class="earn_balance_top">
          <p>WALLET ADDRESS</p>
        </div>
      </div>
      <div class="withdraw_card">
        <input
          class="withdraw_card_num"
          style={{ width: "100%", textAlign: "start", padding: "0 0 0 25px" }}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      <div class="withdraw_button" onClick={() => handleExchange()}>
        <button>Exchange</button>
      </div>
      <div class="withdraw_other">
        <h1>Exchange rate</h1>
        <span>5000000 BIX per BBX</span>
      </div>
      {/*<div class="withdraw_more">
        <img src="/info.svg" alt="" />
        <p>Learn more</p>
        </div>*/}
    </div>
  );
};
