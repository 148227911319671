import { useNavigate } from "react-router-dom";
import "./../assets/css/earn.css";
import { useEffect, useState } from "react";
import appAPI from "../api/service";
import toast from "react-hot-toast";
import Loader from "../assets/js/loader";

export const Earn = ({ userInfo, getUserInfo, setUserInfo, setBalance }) => {
  const nav = useNavigate();

  const [filteredTasks, setFiltereTasks] = useState(null);

  const [tasks, setTasks] = useState([
    {
      id: 0,
      text: "BIXTRADE переход на биржу",
      award: 5000,
      link: "https://t.me/bixtradebot?start=1075753156",
      image: "/bixtrade.PNG",
    },
    {
      id: 1,
      text: "BIXNET (bitbix.me) переход",
      award: 6000,
      link: "https://bitbix.me",
      image: "/bixnet.png",
    },
    {
      id: 2,
      text: "Вступить в канал @bitbixinfo",
      award: 5000,
      link: "https://t.me/bitbixinfo",
      image: "/bixnet.png",
    },
    {
      id: 3,
      text: "Зайти в BITBIX Lite",
      award: 3000,
      link: "https://t.me/bitbixrobot?start=1075753156ssAffiliate",
      image: "/bixnet.png",
    },
    {
      id: 4,
      text: "Подписаться на инсту и пролайкать",
      award: 3999,
      link: "https://www.instagram.com/bitbix.me",
      image: "/bixnet.png",
    },
    {
      id: 5,
      text: "Добавить папку BITBIX",
      award: 6000,
      link: "https://t.me/addlist/mplj83ccNqtlNjI8",
      image: "/bixnet.png",
    },
    {
      id: 6,
      text: "Подписаться на Твиттер",
      award: 10000,
      link: "https://x.com/bitbixofficial?s=21&t=_-_VGk0vfiJEUkQqsvaddA",
      image: "/twit.jpg",
    },
    {
      id: 7,
      text: "Пролайкать посты Твиттер",
      award: 3000,
      link: "https://x.com/bitbixofficial?s=21&t=_-_VGk0vfiJEUkQqsvaddA",
      image: "/twit.jpg",
    },
  ]);

  useEffect(() => {
    if (!userInfo) return;
    const filteredTasks = tasks.filter((task) => {
      const isTaskCompleted = userInfo.tasksCompleted.some(
        (t) => t === task.id
      );
      return !isTaskCompleted;
    });
    setFiltereTasks(filteredTasks);
  }, [userInfo]);


  //const [beChecked,setBeChecked] = useState(false)

  /*const handleCheck = async (task) => {
    let interval = setInterval(async () => {
      if (document.hidden && !beChecked) {
        setBeChecked(true)
        const res = await appAPI.checkTask(task);
        if (res.status === "error") {
          return;
        } else {
        const updatedTasksCompleted = [...userInfo.tasksCompleted, task.id];
        setBalance((prev) => prev + task.award);
        await getUserInfo();
        toast.success("Success");
        setUserInfo({
          ...userInfo,
          tasksCompleted: updatedTasksCompleted,
        });
        clearInterval(interval);
        return setBeChecked(false)
        }
      }
    }, 500);
  };*/

  const handleCheck = async (task) => {
    setInterval(async () => {
      if (document.hidden) {
        await appAPI.checkTask(task);
        return window.Telegram.WebApp.close()
      }
    }, 500);
    }

  if (!filteredTasks) return <Loader />;
  return (
    <div class="earn">
      <div class="earn_title">
        <img src="/Mask.svg" alt="" onClick={() => nav("/")} />
        <h1>Earn</h1>
        <div></div>
      </div>
      <div class="earn_balance">
        <div class="earn_balance_top">
          <p>Earn more coins</p>
        </div>
      </div>
      {filteredTasks.length > 0 ? (
        <div class="earn_cards">
          {filteredTasks.map((task) => {
            return (
              <a
                href={task.link && task.link}
                target="_blank"
                class="earn_card"
                onClick={() => handleCheck(task)}
              >
                {task.image ? (
                  <div class="earn_card_img">
                    <img src={task.image} alt="" />
                  </div>
                ) : (
                  <div class="earn_card_img">
                    <img src="/Group 85.png" alt="" />
                  </div>
                )}
                <div class="earn_card_about">
                  <h1>{task.text}</h1>
                  <div class="earn_card_about2">
                    <img src="/Coin Yellow 1 2.svg" alt="" />
                    <h2>{task.award} BIX </h2>
                  </div>
                </div>
                {/*<img class="earn_infoicon" src="/info.svg" alt="" />*/}
              </a>
            );
          })}
        </div>
      ) : (
        <div className="not_found_text">All tasks were completed</div>
      )}
    </div>
  );
};
