import { useNavigate } from "react-router-dom";
import "./../assets/css/style.css";
import appAPI from "../api/service";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import md5 from "md5";
import Loader from "../assets/js/loader";

export const Main = ({
  userInfo,
  setAmount,
  amount,
  setEnergy,
  energy,
  setLimitClick,
  limitClick,
}) => {
  const nav = useNavigate();

  const [key, setKey] = useState(null);

  const [perClick, setPerClick] = useState(null);
  const [pepeClicked, setPepeClicked] = useState(false);

  const [clickCount, setClickCount] = useState(0);
  const [activeFun, setActiveFun] = useState(null);

  const clickCountRef = useRef(0);
  const closePageRef = useRef(null);

  const [seconds, setSeconds] = useState(0);

  const baseURL = "https://bixtap.click";
  // const auth = window.btoa(window.Telegram.WebApp.initData);
  const auth = window.btoa(
    "query_id=AAFEDzRNAAAAAEQPNE18tejv&user=%7B%22id%22%3A6540281438%2C%22first_name%22%3A%22Daniil%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22d9A2nY3A%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1709476035&hash=ffccaf0d854ec756eee84a03bf4661a534e8ab4ead8f553651a0e83bb1a66194"
  );

  /*
  useEffect(() => {
    async function closeFunction() {
      if (clickCountRef.current !== 0) {
        setClickCount(0);
        setSeconds(0);
        setActiveFun(false);
        clearTimeout(closePageRef);
        let keyQuery = md5(key);
        const res = await appAPI.click(clickCountRef.current, keyQuery);
        if (res.status === "ok") {
          setKey(eval(atob(res.data.f[0])));
        }
      }
    }
    return () => {
      closeFunction();
    };
  }, [key, closePageRef]);*/

  useEffect(() => {
    clickCountRef.current = clickCount;
  }, [clickCount]);

  useEffect(() => {
    if (clickCount >= 1 && !activeFun) {
      setActiveFun(true);
      setSeconds(5);
      let interval = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
      let closePage = setTimeout(async () => {
        try {
          setClickCount(0);
          setSeconds(0);
          clearInterval(interval);
          setActiveFun(false);
          let keyQuery = md5(key);
          const res = await appAPI.click(clickCountRef.current, keyQuery);
          if (res.status === "ok") {
            setKey(eval(atob(res.data.f[0])));
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
        }
      }, 5000);
      closePageRef.current = closePage;
    }
  }, [clickCount, activeFun, key]);

  useEffect(() => {
    if (!userInfo) return;
    setPerClick(userInfo.byClick);
    setKey(eval(atob(userInfo.q[0])));
    console.log(userInfo);
  }, [userInfo]);

  const pepe = useRef(null);
  const dropMiniCoinsInt = useRef(null);

  useEffect(() => {
    if (pepeClicked) return;
    const interval = setInterval(() => {
      const existingFlyingCoin = document.querySelector(".flying");
      if (!existingFlyingCoin && !pepeClicked) {
        createFlyingCoin();
      }
    }, 120000);
    return () => {
      clearInterval(interval);
      if (dropMiniCoinsInt.current) clearInterval(dropMiniCoinsInt.current);
      if (pepe.current) pepe.current.remove();
    };
  }, [pepeClicked]);

  const createFlyingCoin = () => {
    setTimeout(() => {
      clearInterval(dropMiniCoins);
      flyingCoin.remove();
    }, 10000);

    const flyingCoin = document.createElement("img");
    pepe.current = flyingCoin;
    flyingCoin.src = "/pepe.png";
    flyingCoin.classList.add("flying");
    if (Math.random() < 0.33) {
      flyingCoin.classList.add("flying-coin1");
    } else if (Math.random() < 0.66) {
      flyingCoin.classList.add("flying-coin2");
    } else {
      flyingCoin.classList.add("flying-coin3");
    }
    document.body.appendChild(flyingCoin);

    flyingCoin.addEventListener("click", () => {
      setPepeClicked(true);
      setPerClick((prev) => prev * 2);
      clearInterval(dropMiniCoins);
      flyingCoin.remove();

      setTimeout(() => {
        setPepeClicked(false);
        setPerClick((prev) => prev / 2);
      }, 10000);
    });

    const dropMiniCoins = setInterval(() => {
      const miniCoin = document.createElement("img");
      miniCoin.src = "/Coin Yellow 1.png";
      miniCoin.classList.add("mini-coin");
      document.body.appendChild(miniCoin);
      const rect = flyingCoin.getBoundingClientRect();
      miniCoin.style.top = `${rect.top + rect.height / 2}px`;
      miniCoin.style.left = `${rect.left + rect.width / 2}px`;

      setTimeout(() => {
        miniCoin.remove();
      }, 500);
    }, 200);

    dropMiniCoinsInt.current = dropMiniCoins;
  };

  const clickHandlerAnim = (e) => {
    const clickX = e.clientX;
    const clickY = e.clientY;

    const imgElement = document.createElement("img");

    imgElement.src = "/Coin Yellow 1.png";

    imgElement.classList.add("click-animation");
    imgElement.style.top = `${clickY}px`;
    imgElement.style.left = `${clickX}px`;

    document.body.appendChild(imgElement);

    imgElement.classList.add("clicked");

    setTimeout(() => {
      imgElement.remove();
    }, 1000);
  };

  const handleClick = (e) => {
    if (energy - perClick > 0) {
      clickHandlerAnim(e);
      handleClickAction();
      const rect = e.target.getBoundingClientRect();
      const clickX = e.clientX - rect.left;
      const clickY = e.clientY - rect.top;
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;

      const deltaX = clickX - centerX;
      const deltaY = clickY - centerY;

      const tiltX = -(deltaY / centerY) * 20;
      const tiltY = (deltaX / centerX) * 20;

      e.target.style.transition = "transform 0.3s ease";
      e.target.style.transform = `perspective(200px) rotateX(${tiltX}deg) rotateY(${tiltY}deg)`;

      setTimeout(() => {
        e.target.style.transition = "transform 0.1s ease";
        e.target.style.transform =
          "perspective(200px) rotateX(0deg) rotateY(0deg)";
      }, 100);

      //return window.Telegram.WebApp.HapticFeedBack.notificationOccurred("success");
    } else {
      toast.error("Click limit exceeded");
      //return window.Telegram.WebApp.HapticFeedBack.notificationOccurred("success");
    }
  };

  async function handleClickAction() {
    if (limitClick > 0) {
      setAmount((prev) => prev + perClick);
      setEnergy((prev) => prev - perClick);
      if (pepeClicked) {
        setClickCount((prevCount) => prevCount + 2);
      } else {
        setClickCount((prevCount) => prevCount + 1);
      }
    }
  }

  const isFrarmingStateINACTIVE = async () => {
    try {
      const res = await fetch(`${baseURL}/api/farming/start`, {
        method: "POST",
        headers: {
          Authorization: auth,
        },
      });
      console.log(res);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const isFrarmingStateREADY = async () => {
    try {
      const res = await fetch(`${baseURL}/api/farming/claim`, {
        method: "POST",
        headers: {
          Authorization: auth,
        },
      });
      console.log(res);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const [farmed, setFarmed] = useState(userInfo.farmingFarmed);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFarmed((prevFarmed) => prevFarmed + 0.113);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (amount === null) return <Loader />;
  return (
    <div className="scrool">
      <div class="home">
        {pepeClicked && (
          <div className="background">
            {Array.from({ length: 10 }, (_, index) => (
              <div className="background_coins" key={index}>
                <img className="coin" src="/Coin Yellow 1.png" alt="" />
                <img className="coin" src="/Coin Yellow 1.png" alt="" />
                <img className="coin" src="/Coin Yellow 1.png" alt="" />
                <img className="coin" src="/Coin Yellow 1.png" alt="" />
                <img className="coin" src="/Coin Yellow 1.png" alt="" />
              </div>
            ))}
          </div>
        )}
        <div class="home_top_btn" style={{ gap: "10px", padding: "0 12px" }}>
          <button onClick={() => nav("/rating")}>
            Rating <img src="/Mask2.svg" alt="" />
          </button>

          {userInfo.farmingState === "inactive" && (
            <button
              onClick={isFrarmingStateINACTIVE}
              style={{
                background: "#FCA00A",
              }}
            >
              Start farming
            </button>
          )}
          {userInfo.farmingState === "active" && (
            <button onClick={() => {}}>
              Farming
              <span
                style={{
                  color: "#FED436",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginLeft: "5px",
                }}
              >
                {Math.round(farmed)} BIX
              </span>
            </button>
          )}
          {userInfo.farmingState === "ready" && (
            <button
              onClick={isFrarmingStateREADY}
              style={{
                background:
                  "linear-gradient(91deg, #141414 0.34%, #272118 97.19%)",
              }}
            >
              <span
                style={{
                  color: "#FCA410",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginLeft: "5px",
                }}
              >
                Claim 3256 BIX
              </span>
            </button>
          )}
        </div>

        {/* <div className="farming_but_section">
      
      </div> */}
        {Object.entries(userInfo.squad).length > 0 && (
          <div class="home_top_btn_squad">
            {userInfo.squad.photoBase64Url && (
              <img src={userInfo.squad.photoBase64Url} alt="" />
            )}{" "}
            {userInfo.squad.name}
          </div>
        )}
        <div class="home_center">
          <h1 className={String(amount).length > 6 && "smal_numbers"}>
            {amount} <span>BIX</span>
          </h1>
          <img
            src="/Coin Yellow 1.png"
            alt=""
            onClick={(e) => handleClick(e)}
          />
        </div>
        <div class="home_down">
          <div class="home_down_top">
            <div className="timer">
              <h1>
                {energy} <span>/</span> <span>{limitClick}</span>
              </h1>
              {seconds > 0 && (
                <div className="time">Saved after {seconds} seconds</div>
              )}
            </div>

            <div class="line">
              <span
                class="line_span"
                style={{ width: `${(energy / limitClick) * 100}%` }}
              ></span>
            </div>
          </div>
          <div class="home_down_center">
            <div class="home_down_center_card" onClick={() => nav("/earn")}>
              <img src="/shopping-cart.svg" alt="" />
              <p>Earn</p>
            </div>
            <div class="home_down_center_card" onClick={() => nav("/frens")}>
              <img src="/people.svg" alt="" />
              <p>Frens</p>
            </div>
            <div class="home_down_center_card" onClick={() => nav("/boosts")}>
              <img src="/flash.svg" alt="" />
              <p>Boosts</p>
            </div>
          </div>
          {userInfo?.allowWithdraw === false ? (
            <div class="home_down_down not_active_button">
              <button>
                <img src="/lock.svg" />
              </button>
            </div>
          ) : (
            <div class="home_down_down" onClick={() => nav("/withdraw")}>
              <button>Withdraw</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
