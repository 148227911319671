const Loader = () => {
  return (
    <div className="loader_block">
      
      <img src="/loader.svg" alt="Loader" />
    </div>
  );
};

export default Loader;
