import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Main } from "./pages/main";
import { Rating } from "./pages/rating";
import { Withdraw } from "./pages/withdraw";
import { Frens } from "./pages/frens";
import { Boosts } from "./pages/boosts";
import { Earn } from "./pages/earn";
import { useEffect, useState } from "react";
import Loader from "./assets/js/loader";
import appAPI from "./api/service";
import { Toaster } from "react-hot-toast";
import { Squads } from "./pages/squads";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function App() {
  const [userInfo, setUserInfo] = useState(null);
  const [limitClick, setLimitClick] = useState(null);
  const [amount, setAmount] = useState(null);
  const [energy, setEnergy] = useState(null);
  const [refreshClicks, setRefreshClicks] = useState(null);
  const query = useQuery();
  const ref = query.get("ref");
  const squad = query.get("squad");

  useEffect(() => {
    if (!refreshClicks) return;
    const interval = setInterval(() => {
      setEnergy((prev) => {
        if (prev < limitClick) {
          if (prev + refreshClicks > limitClick) return limitClick;
          return prev + refreshClicks;
        }
        return prev;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [refreshClicks]);

  async function getUserInfo() {
    const info = await appAPI.getUser(ref, squad);
    if (info?.status === "ok") {
      setUserInfo(info.data);
      setAmount(parseInt(info.data.balance));
      setEnergy(parseInt(info.data.energy));
      setRefreshClicks(info.data.energySpeedBySecond);
      setLimitClick(info.data.maxEnergy);
    }
  }

  useEffect(() => {
    if (!userInfo) return;
    setEnergy(parseInt(userInfo.energy));
    setRefreshClicks(userInfo.energySpeedBySecond);
    setLimitClick(userInfo.maxEnergy);
  }, [userInfo]);

  useEffect(() => {
    window.Telegram.WebApp.expand();
    getUserInfo();
  }, []);

  if (!userInfo) return <Loader />;
  return (
    <>
      <Toaster />
      <div className="app">
        <Routes>
          <Route
            path="/"
            element={
              <Main
                energy={energy}
                setRefreshClicks={setRefreshClicks}
                limitClick={limitClick}
                setLimitClick={setLimitClick}
                refreshClicks={refreshClicks}
                setEnergy={setEnergy}
                userInfo={userInfo}
                amount={amount}
                setAmount={setAmount}
              />
            }
          />
          <Route path="/frens" element={<Frens userInfo={userInfo} />} />
          <Route
            path="/earn"
            element={
              <Earn
                setBalance={setAmount}
                userInfo={userInfo}
                getUserInfo={getUserInfo}
                setUserInfo={setUserInfo}
              />
            }
          />
          <Route path="/rating" element={<Rating userInfo={userInfo} />} />
          <Route path="/squads" element={<Squads userInfo={userInfo} />} />
          <Route
            path="/withdraw"
            element={
              <Withdraw
                userInfo={userInfo}
                balance={amount}
                setBalance={setAmount}
              />
            }
          />
          <Route
            path="/boosts"
            element={
              <Boosts
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                amount={amount}
                setAmount={setAmount}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
